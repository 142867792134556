// <!-- ENUMS -->
import { TemperatureScale, Unit } from '@/enums';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useAgGrid } from '@/hooks/useAgGrid';

// <!-- COMPONENTS -->
import ColorIconCellRenderer from '~Analysis/components/cell/ColorIconCellRenderer.vue';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { Enum } from '@/utils/enums';
import { useLocationSort } from '@/utils/sorters';
import { useCaseInsensitiveLocaleCompare } from '@/utils/sorters';
import {
    formatInteger,
    formatDecimal,
    formatPercent,
} from '@/utils/formatters';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- TYPES -->
/** @typedef {import('vuex').Store<ECNBState>} Store */
/** @typedef {import('./useCompareMetricsQuery').LocationRisksData & ComputedRisksRowData} ComputedLocationRisksRowData */
/** @typedef {import('./useCompareMetricsQuery').WeatherStationRisksData & ComputedRisksRowData} ComputedWeatherStationRisksRowData */
/** @typedef {Combine<ComputedLocationRisksRowData> | Combine<ComputedWeatherStationRisksRowData>} CompareMetricsRowData */
/**
 * @typedef ComputedRisksRowData
 * @prop {import('@/models/v1/metrics/ResourceRisks').Risk} age_risk
 * @prop {import('@/models/v1/metrics/ResourceRisks').Risk} corr_risk
 * @prop {import('@/models/v1/metrics/ResourceRisks').Risk} mech_risk
 * @prop {import('@/models/v1/metrics/ResourceRisks').Risk} mold_risk
 */

/**
 * @typedef UseCompareMetricsGridOptions
 * @prop {Store} [store] Track an existing store reference, if it was already provisioned.
 * @prop {CompareMetricsRowData[] | Vue.Ref<CompareMetricsRowData[]>} [data] Possibly reactive initial data.
 * @prop {import('@/hooks/grid/useAgGridPagination').UseAgGridPaginationOptions} [pagination]
 * @prop {string | Vue.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 */

/**
 * @typedef UseCompareMetricsGridReturnPartial
 * @prop {Vue.Ref<CompareMetricsRowData[]>} rowData Reactive reference used to track the grid row data.
 * @prop {Readonly<AgGrid.ColumnDef<CompareMetricsRowData>>} defaultColumnDef Default column definition.
 * @prop {Readonly<AgGrid.ColumnDef<CompareMetricsRowData>[]>} columnDefs Ordered array of column definitions.
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isEmpty Is computed metrics collection empty?
 * @prop {(data: Vue.Ref<CompareMetricsRowData[]> | CompareMetricsRowData[]) => void} setRowData Update the reactive `rowData` reference.
 * @prop {import('@vueuse/core').EventHookOn<Events.GridReadyEvent>} onGridReady Fires when the grid is ready.
 * @prop {import('@vueuse/core').EventHookOn<Events.ColumnResizedEvent>} onColumnResized Fires when a grid column is resized.
 */

/**
 * @typedef {UseCompareMetricsGridReturnPartial & ReturnType<useAgGrid<CompareMetricsRowData>>} UseCompareMetricsGridReturn
 */

/**
 * Define the grid composable.
 * @param {UseCompareMetricsGridOptions} [props]
 * @return {UseCompareMetricsGridReturn}
 */
export const useCompareMetricsGrid = (props = {}) => {
    // DESTRUCTURE services.
    const { formatters, comparators } = useServices(props);

    /** DEFINE record of resource labels. */
    const ResourceLabels = Enum.create({
        Location: 'Location',
        WeatherStation: 'Weather Station',
    });

    /** @typedef {ColumnFields['_dictionary']} CompareMetricsColumnFields */

    /** DEFINE column field names. */
    const ColumnFields = Enum.create({
        id: 'id',
        type: 'type',
        name: 'name',
        path: 'path',
        label: 'label',
        minDate: 'minDate',
        maxDate: 'maxDate',
        dateRange: 'dateRange',
        startDate: 'start_date',
        endDate: 'end_date',
        meanTemperature: 'temp_mean',
        meanRelativeHumidity: 'rh_mean',
        meanDewpoint: 'dp_mean',
        lastTWPI: 'last_twpi',
        minDC: 'dc_min',
        maxDC: 'dc_max', // Maximum DC value.
        spanDC: 'dc_span', // Maximum DC span value.
        minEMC: 'emc_min',
        maxEMC: 'emc_max',
        moldRiskFactor: 'mrf',
        ageRisk: 'age_risk',
        corrosionRisk: 'corr_risk',
        mechanicalDamageRisk: 'mech_risk',
        moldRisk: 'mold_risk',
    });

    /**
     * @type {AgGrid.ColumnDef<CompareMetricsRowData>}
     * Default column definition.
     */
    const defaultColumnDef = Object.freeze({
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressMovable: true,
        suppressMenu: true,
        lockPosition: true,
        minWidth: 80,
        flex: 1,
        cellClass: 'flex items-center justify-center leading-5 break-normal',
        // cellClass: 'leading-5 py-2 break-normal',
        headerClass: 'whitespace-normal text-center',
        wrapHeaderText: true,
    });

    /** @type {Readonly<Partial<{ [ K in keyof CompareMetricsColumnFields as CompareMetricsColumnFields[K] ]: AgGrid.ColumnDef }>>} */
    const ColumnSchema = {
        [ColumnFields.id]: {
            headerName: '',
            field: ColumnFields.id,
            maxWidth: 50,
        },
        [ColumnFields.type]: {
            headerName: 'Type',
            field: ColumnFields.type,
            maxWidth: 50,
        },
        [ColumnFields.name]: {
            headerName: 'Location',
            field: ColumnFields.name,
            suppressSizeToFit: false,
            minWidth: 220,
            floatingFilter: false,
            flex: 8,
            // cellClass: 'flex items-center justify-left leading-5 break-normal',
        },
        [ColumnFields.label]: {
            headerName: 'Location',
            field: ColumnFields.label,
            suppressSizeToFit: false,
            minWidth: 425,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            flex: 8,
            sort: 'asc',
            sortingOrder: ['asc', 'desc'],
            comparator: comparators.byResource,
            cellClass: 'flex items-center justify-left leading-5 break-normal',
        },
        [ColumnFields.dateRange]: {
            headerName: 'Date Range',
            field: `${ColumnFields.startDate}&${ColumnFields.endDate}`,
            valueGetter: formatters.useDateRangeFormat,
            minWidth: 210,
            suppressSizeToFit: false,
            wrapText: true,
            autoHeight: true,
            floatingFilter: false,
            flex: 2,
        },
        [ColumnFields.meanTemperature]: {
            headerName: `${formatters.formatTemperatureHeader('Mean', 'T')}`,
            field: ColumnFields.meanTemperature,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.meanRelativeHumidity]: {
            headerName: `${formatters.formatPercentHeader('Mean', 'RH')}`,
            field: ColumnFields.meanRelativeHumidity,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: formatters.useIntegerFormat,
            comparator: comparators.byValue,
        },
        [ColumnFields.meanDewpoint]: {
            headerName: `${formatters.formatTemperatureHeader('Mean', 'DP')}`,
            field: ColumnFields.meanDewpoint,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.lastTWPI]: {
            headerName: 'TWPI',
            field: ColumnFields.lastTWPI,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: formatters.useIntegerFormat,
            comparator: comparators.byValue,
        },
        [ColumnFields.maxDC]: {
            headerName: `${formatters.formatPercentHeader('Max Value', 'DC')}`,
            field: ColumnFields.maxDC,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.spanDC]: {
            /**
             * NOTE: This label is intentionally set to `%DC Max` for legacy reasons.
             * The `dc_span` metric is actually a measurement of the maximum %DC delta between the min and max values.
             * Historically, this has been reported as `%DC Max`, and has inherited this label going forward.
             *
             * This should not be confused with the similar, related field `dc_max` which is an absolute measurement of the maximum %DC value.
             */
            headerName: `${formatters.formatPercentHeader('Max', 'DC')}`,
            field: ColumnFields.spanDC,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.minEMC]: {
            headerName: `${formatters.formatPercentHeader('Min', 'EMC')}`,
            field: ColumnFields.minEMC,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.maxEMC]: {
            headerName: `${formatters.formatPercentHeader('Max', 'EMC')}`,
            field: ColumnFields.maxEMC,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.moldRiskFactor]: {
            headerName: 'MRF',
            field: ColumnFields.moldRiskFactor,
            suppressSizeToFit: false,
            floatingFilter: false,
            valueFormatter: (params) =>
                formatters.useDecimalFormat(params, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            comparator: comparators.byValue,
        },
        [ColumnFields.ageRisk]: {
            headerName: 'Natural Aging',
            field: ColumnFields.ageRisk,
            minWidth: 100,
            cellRendererFramework: ColorIconCellRenderer,
            suppressSizeToFit: false,
            wrapText: true,
            floatingFilter: false,
            flex: 1,
            cellClass: 'flex justify-center items-center',
            comparator: comparators.byRisk,
        },
        [ColumnFields.mechanicalDamageRisk]: {
            headerName: 'Mechanical Damage',
            field: ColumnFields.mechanicalDamageRisk,
            minWidth: 110,
            cellRendererFramework: ColorIconCellRenderer,
            suppressSizeToFit: false,
            wrapText: true,
            floatingFilter: false,
            flex: 1.5,
            cellClass: 'flex justify-center items-center',
            comparator: comparators.byRisk,
        },
        [ColumnFields.corrosionRisk]: {
            headerName: 'Metal Corrosion Risk',
            field: ColumnFields.corrosionRisk,
            minWidth: 100,
            cellRendererFramework: ColorIconCellRenderer,
            suppressSizeToFit: false,
            wrapText: true,
            floatingFilter: false,
            flex: 1.5,
            cellClass: 'flex justify-center items-center',
            comparator: comparators.byRisk,
        },
        [ColumnFields.moldRisk]: {
            headerName: 'Mold Risk',
            field: ColumnFields.moldRisk,
            cellRendererFramework: ColorIconCellRenderer,
            suppressSizeToFit: false,
            wrapText: true,
            floatingFilter: false,
            cellClass: 'flex justify-center items-center',
            comparator: comparators.byRisk,
        },
    };

    // DEFINE grid behaviour.
    const grid = useAgGrid({
        ...props,
        columnSchema: /** @type {any} */ (ColumnSchema),
        defaultColumnDef,
    });

    /**
     * @type {AgGrid.ColumnDef<CompareMetricsRowData>[]}
     * Ordered column definition array.
     */
    const columnDefs = grid.getColumnDefs([
        ColumnFields.label,
        ColumnFields.dateRange,
        ColumnFields.ageRisk,
        ColumnFields.mechanicalDamageRisk,
        ColumnFields.corrosionRisk,
        ColumnFields.moldRisk,
        ColumnFields.meanTemperature,
        ColumnFields.meanRelativeHumidity,
        ColumnFields.meanDewpoint,
        ColumnFields.lastTWPI,
        ColumnFields.spanDC,
        ColumnFields.minEMC,
        ColumnFields.maxEMC,
        ColumnFields.moldRiskFactor,
    ]);

    // EXPOSE
    return {
        ...grid,
        defaultColumnDef,
        columnDefs,
    };
};

/**
 * Define the services used by this composable.
 * @param {Pick<UseCompareMetricsGridOptions, 'store'>} [props] @see {@link UseCompareMetricsGridOptions}
 */
const useServices = (props = {}) => {
    // DEFINE the store.
    const store = props?.store ?? useStore();
    // DEFINE formatters.
    const formatters = Object.freeze({
        /**
         * Get the account's current temperature unit.
         * @returns {Unit['Celsius'] | Unit['Fahrenheit'] | Unit['Unknown']}
         */
        getTemperatureUnit: () => {
            const temperatureScale =
                store.state.accounts.account.temperatureScale;
            switch (temperatureScale) {
                case TemperatureScale.Celsius:
                    return Unit.Celsius;
                case TemperatureScale.Fahrenheit:
                    return Unit.Fahrenheit;
                default:
                    return Unit.Unknown;
            }
        },
        /**
         * Value formatter used to format the date range.
         * @type {AgGrid.ValueGetterFunc<CompareMetricsRowData>}
         */
        useDateRangeFormat: (params) => {
            const {
                start_date = '',
                end_date = '',
                missing = false,
            } = params?.data ?? {};
            const isStartValid =
                is.falsy(missing) &&
                !is.nullOrUndefined(start_date) &&
                !is.emptyStringOrWhitespace(start_date);
            const isEndValid =
                is.falsy(missing) &&
                !is.nullOrUndefined(end_date) &&
                !is.emptyStringOrWhitespace(end_date);
            if (isStartValid && isEndValid) {
                return `${start_date} - ${end_date}`;
            }
            return `No Data Available`;
        },
        /**
         * Format the decimal value into a temperature string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        useIntegerFormat: (params) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {Pick<Intl.NumberFormatOptions, 'minimumIntegerDigits'>} */
            const options = { minimumIntegerDigits: 1 };
            const formatted = formatInteger({
                value: Math.round(value),
                options,
            });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format the decimal value into a percent string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        usePercentFormat: (params) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} */
            const options = {};
            const formatted = formatPercent({ value, options });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format the decimal value.
         * @param {AgGrid.ValueFormatterParams} params
         * @param {Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} [options]
         */
        useDecimalFormat: (params, options = {}) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            const formatted = formatDecimal({ value, options });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @param {String} unit Unit to use.
         * @returns {String}
         */
        formatMetricLabel: (label, tag, unit) => {
            return `${tag}${unit} ${label}`.trimEnd();
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @returns {String}
         */
        formatTemperatureHeader: (label, tag) => {
            const unit = formatters.getTemperatureUnit();
            return `${tag}${unit} ${label}`.trimEnd();
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @returns {String}
         */
        formatPercentHeader: (label, tag) => {
            const unit = Unit.Percent;
            return `${unit}${tag} ${label}`.trimEnd();
        },
    });

    // DEFINE the column schema.
    const sortByLocation = useLocationSort();
    const sortByLocaleName = useCaseInsensitiveLocaleCompare();

    // DEFINE comparators.
    const comparators = Object.freeze({
        /**
         * Sort by the resource type and name.
         * @param {string | null} valueA The left-hand value to compare.
         * @param {string | null} valueB The right-hand value to compare.
         * @param {AgGrid.RowNode<CompareMetricsRowData>} nodeA The left-hand `RowNode` context.
         * @param {AgGrid.RowNode<CompareMetricsRowData>} nodeB The right-hand `RowNode` context.
         * @param {boolean} isDescending `true` if sort direction is `desc`. Not to be used for inverting the return value as the grid already applies `asc` or `desc` ordering.
         * @returns {integer}
         */
        byResource: (valueA, valueB, nodeA, nodeB, isDescending) => {
            // Get relevant node data.
            const _data = { a: nodeA?.data, b: nodeB?.data };

            // Prepare containers for relevant data.
            const _a = { value: valueA, type: 'Location', unassigned: true };
            const _b = { value: valueB, type: 'Location', unassigned: true };

            // Get the first resource's data.
            if (_data.a.type === 'Location') {
                _a.type = 'Location';
                _a.unassigned = _data.a.hierarchyId == null;
                _a.value = _a.unassigned ? _data.a?.name : valueA;
            } else if (_data.a.type === 'Weather Station') {
                _b.type = 'Weather Station';
                _a.value = valueA;
            }

            // Get the second resource's data.
            if (_data.b.type === 'Location') {
                _b.type = 'Location';
                _b.unassigned = _data.b.hierarchyId == null;
                _b.value = _b.unassigned ? _data.b?.name : valueB;
            } else if (_data.b.type === 'Weather Station') {
                _b.type = 'Weather Station';
                _b.value = valueB;
            }

            // Compare two locations.
            if (_a.type === 'Location' && _a.type === _b.type) {
                return sortByLocation(_a, _b);
            }

            // Compare two weather stations.
            if (_a.type === 'Weather Station' && _a.type === _b.type) {
                return sortByLocaleName(_a.value, _b.value);
            }

            // When A < B (eg., A is a Location, but B is a Weather Station).
            if (_a.type === 'Location') {
                return -1;
            }

            // When A > B (eg., A is a Weather Station, but B is a Location).
            if (_b.type === 'Weather Station') {
                return 1;
            }

            // If all else fails, use a locale compare of the names.
            return sortByLocaleName(_a.value, _b.value);
        },
        /**
         * Sort by the risk.
         * @param {import('@/models/v1/metrics/ResourceRisks').Risk | null} valueA The left-hand value to compare.
         * @param {import('@/models/v1/metrics/ResourceRisks').Risk | null} valueB The right-hand value to compare.
         * @param {AgGrid.RowNode} nodeA The left-hand `RowNode` context.
         * @param {AgGrid.RowNode} nodeB The right-hand `RowNode` context.
         * @param {boolean} isDescending `true` if sort direction is `desc`. Not to be used for inverting the return value as the grid already applies `asc` or `desc` ordering.
         * @returns {integer}
         */
        byRisk: (valueA, valueB, nodeA, nodeB, isDescending) => {
            // GET numerical risk values to compare.
            const a = valueA?.value ?? 0;
            const b = valueB?.value ?? 0;
            return a - b;
        },
        /**
         * Sort by the numeric value.
         * @param {number} valueA The left-hand value to compare.
         * @param {number} valueB The right-hand value to compare.
         * @param {AgGrid.RowNode} nodeA The left-hand `RowNode` context.
         * @param {AgGrid.RowNode} nodeB The right-hand `RowNode` context.
         * @param {boolean} isDescending `true` if sort direction is `desc`. Not to be used for inverting the return value as the grid already applies `asc` or `desc` ordering.
         * @returns {integer}
         */
        byValue: (valueA, valueB, nodeA, nodeB, isDescending) => {
            // IF both values are null, return 0.
            if (Number.isNaN(valueA) && Number.isNaN(valueB)) {
                // valueA is the same as valueB.
                return 0;
            }

            // IF valueB is null, A is greater.
            if (Number.isNaN(valueB)) {
                // SORT valueA after valueB.
                return 1;
            }

            // IF valueA is null, B is greater.
            if (Number.isNaN(valueA)) {
                // SORT valueA before valueB.
                return -1;
            }

            // IF neither value is null, return difference between numerical values.
            const a = Number(valueA);
            const b = Number(valueB);
            return a - b;
        },
    });

    // EXPOSE services.
    return {
        store,
        formatters,
        comparators,
    };
};
